<template>
  <MainCardsBlock
    v-element-visibility="(isVisibility) => isVisibility && sendHomeShowShelf(label, {
      shelf_number: shelfNumber,
    })"
    title="Быстрый старт <br> в&nbsp;востребованных направлениях"
    button-text="Открыть все направления"
    button-link="/catalog/"
    :tabs="directionsTabsData"
    :cards="coursesByDirection"
    @click="handleDirectionsTabClick"
    @click:card="(event: any) => {
      sendHomeClickShelf(label, {
        shelf_number: shelfNumber
      })
      sendHomeClickCard({
        shelf_number: shelfNumber,
        card_number: event.cardNumber,
        nomenclature_id: event.nomenclatureId,
        nomenclature_type: event.nomenclatureType,
      })
    }"
    @click:link="sendHomeClickLink(label, {
      link: '/catalog/'
    })"
    @visible:card="(event: any) => sendHomeShowCard({
      shelf_number: shelfNumber,
      card_number: event.cardNumber,
      nomenclature_id: event.nomenclatureId,
      nomenclature_type: event.nomenclatureType,
    })"
  />
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'
  import { vElementVisibility } from '@vueuse/components'

  import { directionsTabsData } from '@/pages/mocks'
  import { useMainStore } from '@/store/main'

  import { sendHomeShowShelf, sendHomeClickShelf, sendHomeClickCard, sendHomeClickLink, sendHomeShowCard } from '@/pages/index/analytics'

  const label = 'fast_start'
  const shelfNumber = 5

  const mainStore = useMainStore()
  const { coursesByDirection } = storeToRefs(mainStore)

  const promises: Promise<void>[] = []

  if (directionsTabsData[0]?.slug) {
    promises.push(mainStore.getCoursesByDirection(directionsTabsData[0].slug))
  }

  useAsyncData(
    'getCoursesByDirection',
    () => Promise.all(promises)
  )

  const handleDirectionsTabClick = async (tab: { title: string; slug: string; }) => {
    await mainStore.getCoursesByDirection(tab.slug)
  }
</script>
